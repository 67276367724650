
export function hase(user, fonction) {
   let array = user.fonctions;
   let count = 0;
   if (array) {
      for (var i = 0; i < array.length; i++) {
         if ((array[i].show == 1) && (fonction.toLowerCase() == array[i].libelle.toLowerCase())) {
            count++;
         }
      }
   }
   if (count) return true;
   return false;
}

