import Vue from "vue";

// axios
import axios from "axios";

const axiosIns = axios.create({
	// You can add your headers here
	// ================================
	//baseURL: 'http://apiflashcar.localhost/api/',
	//baseURL: 'https://api.flashcar.waouhmonde.com/api/',
	baseURL: 'https://api-papeteria.flashcar.app/api/',
	//baseURL: 'https://api.mounir.flashcar.app/api/',
	//baseURL: 'https://api.961.flashcar.app/api/',
	//baseURL: 'http://localhost:8000/api/',
	// timeout: 1000,
	// headers: {'X-Custom-Header': 'foobar'}
	headers: { Authorization: `Bearer ${localStorage.getItem("flash_token")}` },
});

Vue.prototype.$http = axiosIns;


export default axiosIns;
