import { hase } from "@/libs/hase"

let scopesAsString = localStorage.getItem("flash_sc");
let scopesAsArray = scopesAsString.split(" ");
// console.log(scopesAsArray)

let routes = [
  {
    title: 'Tableau de bord',
    route: 'home',
    icon: 'HomeIcon',
    scope: 'Tableau-de-bord',
  },

  {
    title: 'Paramètres',
    route: '',
    scope: 'Devises',
    icon: 'SettingsIcon',
    enfants: [
      {
        title: 'Devises',
        route: 'parametres/devises',
        icon: 'DollarSignIcon',
        scope: 'Devises'
      },

      {
        title: 'Signataires',
        route: 'parametres/signataires',
        icon: 'AtSignIcon',
        scope: 'Signataires'
      },

      {
        title: 'Navires',
        route: 'parametres/navires',
        icon: 'MapIcon',
        scope: 'Navires'
      },

      {
        title: 'Clients',
        route: 'parametres/clients',
        icon: 'SmileIcon',
        scope: 'Clients'
      },

      {
        title: 'Sociéte',
        route: 'parametres/societes',
        icon: 'TwitchIcon',
        scope: 'Sociétés'
      },

      {
        title: 'Chargeurs',
        route: 'parametres/chargeurs',
        icon: 'TrelloIcon',
        scope: 'Chargeurs'
      },

      {
        title: 'Acheteurs',
        route: 'parametres/acheteurs',
        icon: 'FramerIcon',
        scope: 'Acheteurs'
      },

      {
        title: 'Associés',
        route: 'parametres/associes',
        icon: 'ActivityIcon',
        scope: 'Associés'
      },

      {
        title: 'Démarcheurs',
        route: 'parametres/demarcheurs',
        icon: 'UserPlusIcon',
        scope: 'Démarcheurs'
      },

      {
        title: 'Caractéristiques véhicules',
        route: 'parametres/caracteristiquesvehicules',
        icon: 'FeatherIcon',
        scope: 'Caractéristique-véhicules'
      },

      {
        title: 'Partenaires',
        route: 'parametres/partenaires',
        icon: 'UsersIcon',
        scope: 'Partenaires'
      },
    ],
  },

  {
    title: 'Manifestes',
    route: 'manifestes',
    icon: 'SlidersIcon',
    scope: "Manifestes",
  },

  {
    title: 'Vehicules',
    route: 'vehicules',
    icon: 'TruckIcon',
    scope: "Véhicules",
  },

  {
    title: 'Personnels',
    route: '',
    scope: 'Personnel',
    icon: 'UsersIcon',
    enfants: [
      {
        title: 'Membres',
        route: 'personnels-membres',
        icon: 'UsersIcon',
        scope: 'Personnel',
      },

      {
        title: 'Salaires',
        route: 'personnels-salaires',
        icon: 'DollarSignIcon',
        scope: 'Personnel',
      },

      {
        title: 'Congés',
        route: 'personnels-conges',
        icon: 'AwardIcon',
        scope: 'Personnel',
      },
    ],
  },
  {
    title: 'Importation',
    route: '',
    scope: "Importateurs",
    icon: 'ActivityIcon',
    enfants: [
      {
        title: 'Importateur',
        route: 'importation/importateurs',
        icon: 'UserIcon',
        scope: "Importateurs",
      },
      {
        title: 'Manifestes',
        route: 'importation/manifestes',
        icon: 'SlidersIcon',
        scope: "Importations-Manifestes"
      },
      {
        title: 'Vehicules',
        route: 'importation/vehicules',
        icon: 'TruckIcon',
        scope: "Importations-Vehicules"
      },
    ],
  },
  {
    title: 'Associations',
    route: 'associations',
    icon: 'GitBranchIcon',
    scope: "Associations",
  },
  {
    title: 'Factures',
    route: '',
    icon: 'ClipboardIcon',
    scope: "Factures",
    enfants: [
      {
        title: 'Facture Pro-Forma',
        route: 'facture/proforma',
        icon: 'FileTextIcon',
        scope: "Factures",
      },
      {
        title: 'Factures',
        route: 'factures',
        icon: 'SlidersIcon',
        scope: "Factures",
      },
    ],
  },
  {
    title: 'Finances',
    icon: 'DollarSignIcon',
    scope: "Finances",
    enfants: [
      {
        title: 'Banque',
        route: 'banque',
        icon: 'UsersIcon',
        scope: "Banque",
      },
      {
        title: 'Journal de caisse',
        route: 'journal-caisse',
        icon: 'FileTextIcon',
        scope: "Journal-de-Caisse",
      },
      {
        title: 'Comptes',
        route: 'comptes',
        icon: 'UsersIcon',
        scope: "Comptes",
      },
      {
        title: 'Prêts/Recouvrement',
        route: 'finances-pretsRecouvrement',
        icon: 'FramerIcon',
        scope: 'Personnel',
      },

      {
        title: 'Dédomagements',
        route: 'dedomagements',
        icon: 'RepeatIcon',
        scope: "Véhicules",
      },

      {
        title: 'Recouvrements',
        route: 'recouvrements',
        icon: 'CreditCardIcon',
        scope: "Recouvrement-à-faire",
      },
    ],
  },
  {
    title: 'Inventaire',
    route: 'inventaire',
    icon: 'ClipboardIcon',
    scope: "Inventaire"
  },
  {
    title: 'Archives',
    icon: 'ArchiveIcon',
    scope: "Archives",
    enfants: [
      {
        title: 'Vehicules',
        route: 'archives/vehicules',
        icon: 'TruckIcon',
        scope: "Archives",
      },
      {
        title: 'Proforma',
        route: 'archives/proforma',
        icon: 'ClipboardIcon',
        scope: "Archives",
      },
      {
        title: 'Factures',
        route: 'archives/factures',
        icon: 'ClipboardIcon',
        scope: "Archives",
      },
      {
        title: 'Manifestes',
        route: 'archives/manifestes',
        icon: 'SlidersIcon',
        scope: "Archives",
      },
      {
        title: 'Journal de caisse',
        route: 'archives/caisse',
        icon: 'FileTextIcon',
        scope: "Archives",
      },
      {
        title: 'Comptes',
        route: 'archives/comptes',
        icon: 'UsersIcon',
        scope: "Archives",
      },
      {
        title: 'Dédomagements',
        route: 'archives/dedomagements',
        icon: 'RepeatIcon',
        scope: "Archives",
      },
    ],
  },

  {
    title: 'Utilisateurs',
    route: 'utilisateurs/utilisateurs',
    icon: 'UserIcon',
    scope: "Utilisateurs",
    enfants: [
      {
        title: 'Groupes utilisateurs',
        route: 'utilisateurs/groups',
        icon: 'UsersIcon',
        scope: "Utilisateurs",
      },
      {
        title: 'Utilisateurs',
        route: 'utilisateurs/utilisateurs',
        icon: 'UserIcon',
        scope: "Groupe-Utilisateurs",
      },
    ],

  },
]

function hasScope(fonction, array) {
  if (array.includes(fonction)) {
    return true
  } else {
    return false
  }
}

function hasScopes(fonctions, array) {
  let notFound = 0
  let Found = 0

  fonctions.forEach(element => {
    if (!array.includes(element)) {
      notFound++
    } else {
      Found++
    }
  });

  if (notFound) {
    return false
  } else {
    return true
  }
}

function checkRoutes(routes) {
  var arrayMenu = []
  routes.forEach((route, index) => {
    // parents
    if (route.scope) {
      if (Array.isArray(route.scope)) {
        if (hasScopes(route.scope, scopesAsArray)) {
          arrayMenu.push(routes[index])
        }
      } else {
        if (hasScope(route.scope, scopesAsArray)) {
          if (routes[index].enfants) {
            let enfants = routes[index].enfants;
            let enfantsMenu = [];
            enfants.forEach((childRoute, index) => {
              if (childRoute.scope) {
                if (hasScope(childRoute.scope, scopesAsArray)) {
                  enfantsMenu.push(enfants[index])
                }
              }
            })
            delete routes[index].enfants
            routes[index]["children"] = enfantsMenu
            arrayMenu.push(routes[index])
          } else {
            arrayMenu.push(routes[index])
          }
        }
      }
    }
  });

  return arrayMenu
}

checkRoutes(routes);


// Vérifier si il existe de société principale et si elle est importatrice
let societyAsImportateur = localStorage.getItem("societyAsImportateur");
if (!societyAsImportateur || parseInt(societyAsImportateur) == 0) {
  let ImpRoute = routes.find((_route) => _route.title === "Importation")
  if (ImpRoute) {
    routes.forEach((element, i) => {
      if (element.title === "Importation") {
        routes.splice(i, 1);
      }
    });
  }
}


// let subscription = JSON.parse(localStorage.getItem("subscription"));
let PrivilegeRoute = routes.find((_route) => _route.title === "Utilisateurs")
let PersonnelRoute = routes.find((_route) => _route.title === "Personnels")
let ImportateurRoute = routes.find((_route) => _route.title === "Importation")
// if(subscription.parc == "Diesel"){
//   if (PersonnelRoute) {
//     routes.forEach((element, i) => {
//       if (element.title == "Personnels") {
//         routes.splice(i, 1);
//       }
//     });
//   }
//   if (PrivilegeRoute) {
//     PrivilegeRoute.children.forEach((element, i) => {
//       if (element.title == "Groupes utilisateurs") {
//         PrivilegeRoute.children.splice(i, 1);
//       }
//     });
//   }
// }
// if(subscription.parc == "Diesel" || subscription.parc == "Essence"){
//   if (ImportateurRoute) {
//     routes.forEach((element, i) => {
//       if (element.title === "Importation") {
//         routes.splice(i, 1);
//       }
//     });
//   }
// }
export default checkRoutes(routes);
