<template>
  <div class="navbar-container d-flex content align-items-center">
    <!-- Nav Menu Toggler -->
    <ul class="nav navbar-nav d-xl-none">
      <li class="nav-item">
        <b-link class="nav-link" @click="toggleVerticalMenuActive">
          <feather-icon icon="MenuIcon" size="21" />
        </b-link>
      </li>
    </ul>

    <!-- Left Col -->
    <div
      class="bookmark-wrapper align-items-center flex-grow-1 d-none d-lg-flex"
    >
      <dark-Toggler class="d-none d-lg-block" />
    </div>

    <b-navbar-nav class="nav align-items-center ml-auto">
      <b-nav-item-dropdown
        right
        toggle-class="d-flex align-items-center dropdown-user-link"
        class="dropdown-user"
      >
        <template #button-content>
          <div class="d-sm-flex d-none user-nav">
            <p class="user-name font-weight-bolder mb-0">
              {{ user.name }}
            </p>
            <span class="user-status">{{
              user.user_group ? user.user_group.libelle : "..."
            }}</span>
          </div>
          <b-avatar
            size="40"
            variant="light-primary"
            badge
            :src="require('@/assets/images/avatars/13-small.png')"
            class="badge-minimal"
            badge-variant="success"
          />
        </template>

        <b-dropdown-item
          :to="{ name: 'utilisateurs-profile' }"
          link-class="d-flex align-items-center"
        >
          <feather-icon size="16" icon="UserIcon" class="mr-50" />
          <span>Mon compte</span>
        </b-dropdown-item>

        <b-dropdown-divider />

        <b-dropdown-item
          @click="showSubscription()"
          link-class="d-flex align-items-center"
        >
          <feather-icon size="16" icon="ActivityIcon" class="mr-50" />
          <span>Mon abonnement</span>
        </b-dropdown-item>
        <b-dropdown-item
          @click="our_backup_database()"
          link-class="d-flex align-items-center"
        >
          <feather-icon size="26" icon="SaveIcon" class="mr-50" />
          <span>Sauvegarde</span>
        </b-dropdown-item>
       <!-- <b-dropdown-item
          @click="synchro()"
          link-class="d-flex align-items-center"
        >
          <feather-icon size="26" icon="SaveIcon" class="mr-50" />
          <span>Synchronisation</span>
        </b-dropdown-item> -->

        <b-dropdown-divider />

        <b-dropdown-item
          @click="logout()"
          link-class="d-flex align-items-center"
        >
          <feather-icon size="16" icon="LogOutIcon" class="mr-50" />
          <span>Déconnexion</span>
        </b-dropdown-item>
      </b-nav-item-dropdown>
    </b-navbar-nav>

    <b-modal
      title="Détails de votre abonnement"
      v-model="modalSubscription"
      centered
      :hide-footer="true"
      ok-only
    >
      <div class="my-1">
        <b-media no-body>
          <b-media-aside class="mr-1">
            <feather-icon class="text-primary" size="30" icon="BriefcaseIcon" />
          </b-media-aside>
          <b-media-body>
            <h6 class="mb-0">TYPE</h6>
            <small> {{ subscription.type }} </small>
          </b-media-body>
        </b-media>
        <hr />
        <b-media no-body>
          <b-media-aside class="mr-1">
            <feather-icon class="text-primary" size="30" icon="CalendarIcon" />
          </b-media-aside>
          <b-media-body>
            <h6 class="mb-0">EXPIRATION</h6>
            <small>
              {{ subscription.expiration ? subscription.expiration : "-----" }}
            </small>
          </b-media-body>
          <div >

          </div>
          <b-form-group v-if="subscription.type!=='vie'" class="col pr-0" style="margin-top: -2%">
            <validation-provider
                #default="{ errors }"
                name="type"
            >
              <label for="type">Type d'abonnement</label>
              <b-form-select
                  id="chargeur"
                  v-model="type_abonnement"
                  :options="abonnement_types"
                  value-field="value"
                  @change="ckecktype"
                  text-field="text"
              />
            </validation-provider>
          </b-form-group>
          </b-media>
        <b-button
            v-if="subscription.type!=='vie'"
            style="margin-top: 8%;float: right;color: #BF6000"
            variant="primary"
            class="mb-2 btn-sm-block"
            @click="paiement()"
        >
          Renouveller
        </b-button>
      </div>
      <!-- <p class="text-center">
        <span> Récupération des informations en cours... </span>
      </p> -->
    </b-modal>
  </div>
</template>

<script>
import { mapState } from "vuex";
import moment from 'moment'

import {
  BLink,
  BNavbarNav,
  BNavItemDropdown,
  BDropdownItem,
  BDropdownDivider,
  BButton,
  BAvatar,
  BFormGroup,
  BMedia,
  BMediaAside,
  BMediaBody, BFormSelect,
} from "bootstrap-vue";
import DarkToggler from "@core/layouts/components/app-navbar/components/DarkToggler.vue";
//import {openKkiapayWidget, addKkiapayListener} from "kkiapay";
import ToastificationContent from "@core/components/toastification/ToastificationContent";

export default {
  components: {
    BLink,
    BNavbarNav,
    BNavItemDropdown,
    BDropdownItem,
    BFormSelect,
    BFormGroup,
    BDropdownDivider,
    BAvatar,
    BButton,
    BMedia,
    BMediaAside,
    BMediaBody,

    // Navbar Components
    DarkToggler,
  },
  data() {
    return {
      modalSubscription: false,
      subscription: {},
      amount_paiement:150000,
      type_abonnement:"annuel",
      abonnement_types: [
        { text: "Annuel", value: "annuel" },
        { text: "A vie", value: "vie" },
      ],
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.user.auth,
    }),
    isSubscribed() {
      return store.state.subscription.isSubscribed
    }
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {},
    },
  },
  beforeCreate() {
    this.$store.dispatch('verifySubscription')
    // let subscription = JSON.parse(localStorage.getItem("subscription"))
    // if (subscription) {
    //   var now = Date.parse(new Date());
    //   var expiration = Date.parse(subscription.expiration);
    //   if (now > expiration) {
    //     this.$router.push({ name: "subscription" });
    //   }
    // } else {
    //   this.$router.push({ name: "subscription" });
    // }

    // let host = window.location.host;
    // this.$http
    //   .get(`/abonnement/${host}`)
    //   .then((res) => {
    //     this.isLoading = false;
    //     let response = res.data;
    //     if (response.success) {
    //       this.informations = response.data;
    //       localStorage.setItem(
    //         "subscription",
    //         JSON.stringify(this.informations)
    //       );
    //       this.$router.push({ name: "home" });
    //     } else {
    //     }
    //   })
    //   .catch((e) => {
    //     this.isLoading = false;
    //   });

    // let subscription = localStorage.getItem("subscription");
    // if (subscription) {
    //   this.subscription = JSON.parse(subscription);
    //   var now = Date.parse(new Date());
    //   var expiration = Date.parse(this.subscription.expiration);
    //   if (this.subscription.type != "vie" && now > expiration) {
    //     this.$router.push({ name: "subscription" });
    //   }
    // } else {
    //   this.$router.push({ name: "subscription" });
    // }
  },
  watch: {
    isSubscribed: function(subscribed) {
      if (subscribed == false) {
        localStorage.removeItem("flash_token");
        localStorage.removeItem("flash_sc");
        localStorage.removeItem("coco_act");
        this.$router.push({ name: "subscription" })
      } else {
        this.subscription = this.$store.state.subscription.subscriptionData
      }
    }
  },
  mounted() {
    this.$http.get("/user").then((res) => {
      let use = res.data.data;
      let text = res.data.data.scope.toString();
      text = text.replace(/,/g, " ");
      localStorage.setItem("coco_act", use.state);
      localStorage.setItem("flash_sc", text);
      //addKkiapayListener('success',this.successHandler)
      localStorage.setItem(
        "societyAsImportateur",
        res.data.data.societyAsImportateur
      );
      this.$store.commit("AUTH_USER", res.data.data);
    });
  },
  methods: {
    showSubscription() {
      this.modalSubscription = true;
    },
    ckecktype(item){
      if (item==="annuel"){
        this.amount_paiement=150000
      }else{
        this.amount_paiement=999900
      }
    },
    paiement(){
 /*     this.modalSubscription=false;
      openKkiapayWidget({
        amount: 4000,
        api_key: "1fd074003bf311edafa2d398c4589a54",
        sandbox: true,
        phone: "97000000",
      });*/
     /* openKkiapayWidget({amount:this.amount_paiement,position:"right",callback:"/login",
        data:"",
        theme:"#BF6000",
        sandbox: true,
        api_key:"1fd074003bf311edafa2d398c4589a54"})*/
    },
   /* successHandler(response) {
      response.data=window.location.host
      this.$http
          .post('/renouvellement/'+this.type_abonnement,response)
          .then((res) => {
            this.isLoading = false;
            if (res.status===200) {
              this.showToast(res.data, "success");
              location.reload()
            } else {
              this.showToast("Renouvellement d'abonnement échoué", "danger");
            }
          })
          .catch((e) => {
            this.isLoading = false;
          });
    },*/
    our_backup_database() {
     this.isLoading = true;
      this.$http
          .get("/our_backup_database")
          .then((res) => {
            this.isLoading = false;
            let blob = new Blob([res.data], {
              type: 'application/pdf'
            })
            let link = document.createElement('a')
            link.href = window.URL.createObjectURL(blob)
            let currentDate = new Date();
           let date_jour= moment(String(currentDate)).format('DD_MM_YYYY')
            link.download = 'flashcar_sauvegarde_'+date_jour+'.sql'
            link.click()
            this.showToast('Sauvegarde de donnée effectuée avec succès','success')

          })
          .catch((e) => {
            console.log("erreur de récupération:" + e.response.data.message);
          });
    },
    synchro() {
     this.isLoading = true;
      this.$http
          .get("/sync")
          .then((res) => {
            this.isLoading = false;
            console.log(res)
          })
          .catch((e) => {
            console.log("erreur de récupération:" + e.response.data.message);
          });
    },
    showToast(titre, type) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: titre,
          icon: type === "success" ? "CheckSquareIcon" : "AlertCircleIcon",
          variant: type,
        },
      });
    },

    logout() {
      this.$http.post("/logout").then((res) => {
        localStorage.removeItem("flash_token");
        localStorage.removeItem("flash_sc");
        localStorage.removeItem("coco_act");
        this.$router.push({ name: "login" });
      });
    },
  },
};
</script>
<style>
.header-navbar .navbar-container ul.navbar-nav li.dropdown-user .dropdown-menu {
  width: 14rem !important;
}
</style>
