<template>
  <b-row
    v-if="$route.meta.breadcrumb || $route.meta.pageTitle"
    class="content-header"
  >
    <!-- Content Left -->
    <b-col class="content-header-left mb-2" cols="12" md="11">
      <b-row class="breadcrumbs-top">
        <b-col cols="12">
          <h2 class="content-header-title float-left pr-1 mb-0">
            {{ $route.meta.pageTitle }}
          </h2>
          <div class="breadcrumb-wrapper">
            <b-breadcrumb>
              <b-breadcrumb-item
                class="colorBreadcrumbItem"
                v-for="item in $route.meta.breadcrumb"
                :key="item.text"
                :active="item.active"
                :to="item.to"
                @click="reloadpage()"
              >
                <span v-if="$route.name == 'associations-show'">
                  Détails association {{ association_name }}
                </span>
                <span v-else>
                  {{ item.text }}
                </span>
              </b-breadcrumb-item>
            </b-breadcrumb>
          </div>
        </b-col>
      </b-row>
    </b-col>
    <!-- <b-col cols="1" class="content-header-right mb-2">
      <div
        v-if="$route.name != 'home'"
        class="icon-wrapper cursor-pointer d-flex justify-content-end"
        @click="$router.go(-1)"
      >
        <b-button variant="warning" class="btn-icon" size="sm">
          <feather-icon icon="ChevronsLeftIcon" size="18" />
          <feather-icon icon="ChevronsLeftIcon" size="18" />
          <feather-icon icon="ChevronsLeftIcon" size="18" />
        </b-button>
      </div>
    </b-col> -->
  </b-row>
</template>

<script>
import {
  BBreadcrumb,
  BBreadcrumbItem,
  BRow,
  BCol,
  BButton,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";

export default {
  directives: {
    Ripple,
  },
  components: {
    BBreadcrumb,
    BBreadcrumbItem,
    BButton,
    BRow,
    BCol,
  },
  computed: {
    association_name() {
      // return localStorage.getItem("association_name");
      return ""
    },
  },

  // mounted() {
  //   console.log(this.$route.meta.breadcrumb);
  //   this.$route.meta.breadcrumb.push({
  //     active: true,
  //     text: "association",
  //   });
  // },

  methods: {
    // Rechargement de la page
    reloadpage() {
      location.reload();
    },
  },
};
</script>
<style>
.colorBreadcrumbItem {
  /* color: #fff5e7 !important; */
  background: #fff !important;
  border-radius: 6px !important;
  padding-left: 10px !important;
  padding-right: 10px !important;
}

/* .colorBreadcrumbItem a{
		color: #fff5e7 !important;
	} */
</style>
